body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.messageInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 71px; margin-left: 8px;}
.creatCampaignInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 33px; margin-left: 8px;}

@media only screen  and (max-width : 1024px)  {
  .messageInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 71px; margin-left: 8px;}
  .creatCampaignInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 33px; margin-left: 8px;}
}
@media only screen  and (max-width : 768px)  {
  .messageInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 71px; margin-left: 8px;}
  .creatCampaignInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 32px; margin-left: 8px;}
} 
@media only screen  and (max-width : 414px)  {
  .messageInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 71px; margin-left: 8px;}
  .creatCampaignInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 34px; margin-left: 8px;}
  .fc .fc-toolbar.fc-header-toolbar{display: grid; justify-content: center; text-align: center;}
  .fc .fc-button-group{margin-top: 6px;}
}
@media only screen  and (max-width : 375px)  {
  .messageInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 90px; margin-left: 8px;}
  .creatCampaignInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 33px; margin-left: 8px;}
  .fc .fc-toolbar.fc-header-toolbar{display: grid; justify-content: center; text-align: center;}
  .fc .fc-button-group{margin-top: 6px;}
}
@media only screen  and (max-width : 360px)  {
  .messageInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 90px; margin-left: 8px;}
  .creatCampaignInput{display: flex; align-items: center; justify-content:space-between; position: relative; bottom: 34px; margin-left: 8px;}
  .fc .fc-toolbar.fc-header-toolbar{display: grid; justify-content: center; text-align: center;}
  .fc .fc-button-group{margin-top: 6px;}
}


.emoji-picker-react{
  width: 400px !important;
}

.fc .fc-button-primary{
  background: #fb6e8a !important;
  border: #fb6e8a !important;
  margin: 0px 2px !important;
  border-radius: 30px !important;
  padding: 4px 8px !important;
  text-transform: capitalize !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.fc .fc-button-primary:focus,.fc .fc-button-primary:active{
  outline: none !important;
  box-shadow: none !important;
  background: #fca0b3 !important;
}

.fc-scrollgrid{
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

/* .fc-h-event .fc-event-main-frame{
  padding: 3px 5px !important;
  margin: 0px 0px !important;
} */

.fc-h-event .fc-event-main{
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: 'Roboto' !important;  
}

.IndividualScheduleIcon{background: #302fed !important; padding: 5px !important; font-size: 12px !important; font-family: 'Roboto';}
.CampaignScheduleIcon{color:#fff; background:#fb6e8a !important; padding: 5px !important; font-size: 12px !important; font-family: 'Roboto';}
.CampaignSentIcon{color:#fff; background:#44dcf1 !important; padding: 5px !important; font-size: 12px !important; font-family: 'Roboto';}
.IndividualSentIcon{color:#fff; background:#0e171e !important; padding: 5px !important; font-size: 12px !important; font-family: 'Roboto';}
.SequenceScheduleIcon{border-radius:4px !important;border:0px !important;margin-left:2px;color:#fff; background:#38a2a0 !important; padding: 5px !important; font-size: 12px !important; font-family: 'Roboto';}
.SequenceSentIcon{height: 26px;border:0px !important;border-radius:4px !important;margin-left:2px;color:#fff; background:#197b30 !important; padding: 5px !important; font-size: 12px !important; font-family: 'Roboto';}
.SpecialScheduleIcon{color:#fff; background:#00d403 !important; padding: 5px !important; font-size: 12px !important; font-family: 'Roboto';}
.SpecialSentIcon{color:#fff; background:#ad387c !important; padding: 5px !important; font-size: 12px !important; font-family: 'Roboto';}

.fc-daygrid-event-dot {display: none;}
.fc-daygrid-event{white-space: normal !important}
.fc-prev-button{width: 36px;display: flex !important;justify-content: center;align-items: center;height: 34px;}
.fc-next-button{width: 36px;display: flex !important;justify-content: center;align-items: center; height: 34px;}
.fc .fc-col-header-cell-cushion{padding: 10px 0px 11px 0px !important;}

.MuiPickersToolbarText-toolbarTxt {color: #333 !important;}
h6.MuiPickersToolbarText-toolbarBtnSelected {color: #fff !important; padding:0px 3px !important; background: #333;}

/* CampaignScheduleIcon:{fontSize:19, marginTop:-1, color:'#fb6e8a'},
    CampaignSentIcon:{fontSize:19, marginTop:-1, color:'#44dcf1'},

    SequenceScheduleIcon:{fontSize:19, marginTop:-1, color:'#38a2a0'},
    SequenceSentIcon:{fontSize:19, marginTop:-1, color:'#197b30'},

    IndividualScheduleIcon:{fontSize:19, marginTop:-1, color:'#302fed'},
    IndividualSentIcon:{fontSize:19, marginTop:-1, color:'#0e171e'},

    SpecialScheduleIcon:{fontSize:19, marginTop:-1, color:'#00d403'},
    SpecialSentIcon:{fontSize:19, marginTop:-1, color:'#ad387c'} */